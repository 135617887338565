


























































































































































































































































































































import { computed, defineComponent, ref, watch, onMounted, onUnmounted } from '@vue/composition-api';
import { useWysiwygSetting } from '@/composition/wysiwygSetting';
import { useNotification } from '@/composition/notification';
import { useMessage, notificationTimeOptions } from '@/admin/message';
import { useConfirm } from '@/composition/confirm';
import officialUsers from '@/composition/officialUser';
import FcDate from '@/components/FcDate.vue';
import FcImage from '@/components/FcImage.vue';
import FcStaticImage from '@/components/FcStaticImage.vue';
import FcAuthority from '@/components/FcAuthority.vue';
import FcWysiwyg from '@/components/FcWysiwyg.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';
import { getStorageValue } from '@/admin/util';
// NOTE: 問題が発生する場合があり、原因の特定ができていないため、一旦コメントアウトで確実に問題ない状態に戻しておく
import { isUploading } from '@/composition/ckeditor';
// import { uploadingLists } from '@/composition/ckeditor';

export default defineComponent({
  name: 'Message',
  components: {
    FcDate,
    FcImage,
    FcStaticImage,
    FcAuthority,
    FcWysiwyg,
    FcRoleLoading,
    FcRoleDeny,
  },
  props: {
    notificationId: {
      type: String,
      require: false,
      default: '',
    },
  },
  beforeRouteLeave(from, to, next) {
    // NOTE: 問題が発生する場合があり、原因の特定ができていないため、一旦コメントアウトで確実に問題ない状態に戻しておく
    if (isUploading.value) {
    // if (uploadingLists.value.length > 0) {
      this.notification.error('画像をアップロード中ですので、少々お待ちください。');
      next(false);
    } else {
      next();
    }
  },
  setup(props, context) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('messages')); // メッセージボックスのロール設定を保持する

    const { message } = useMessage(props.notificationId);
    const { confirmDialog } = useConfirm();
    const wysiwygSetting = useWysiwygSetting();
    wysiwygSetting.fetchConsoleMenu();
    const notification = useNotification();
    const isSaving = ref(false);

    // 公式ユーザーの選択肢を保持する
    const senderOptions = computed(() =>
      officialUsers.getOfficialUserOptions(true, '表示しない', myRoleSettings.value?.unrestrictedByLinkedOfficialUsers)
    );

    // ログインユーザーのメッセージボックスの紐付けサブスクリプションプランのロール設定を取得する
    const SubscriptionPlansRoleSetting = computed(() => myRoleSettings.value?.unrestrictedBySubscriptionPlans);

    // storage関連
    const isFetch = ref(!props.notificationId); // 既存データの取得ステータス（idがない場合fetchがないのでこの初期値）
    const isDialog = ref(false);
    const value = getStorageValue<'message'>(message.storageKey);
    // json格納
    if (value) message.transformStorage(value);
    const type = context.root.$route.query?.type ? context.root.$route.query.type.toString() : '';
    const isOpenDialog = (!type || type === 'draft') && value;
    // dialogの操作
    if (isOpenDialog) isDialog.value = true;
    else message.init();
    // dialogのボタン挙動
    const agree = async () => {
      await message.setStorageValue();
      isDialog.value = false;
    };
    const disagree = async () => {
      await message.init();
      isDialog.value = false;
    };
    // 編集/リロードした際にストレージに保管
    watch(
      () => [message.item, message.target],
      () => {
        // 取得による変更の場合編集ではないので、isFetchのみ変更
        if (!isFetch.value) isFetch.value = true;
        else message.setStorage();
      },
      {
        deep: true,
      }
    );

    const reservationStatusOptions = computed(() => {
      const _options = [
        {
          value: 'draft',
          text: '下書き',
        },
      ];
      // 通知権限がある場合、通知予約を選択肢に追加
      if (myRoleSettings.value && myRoleSettings.value.createNotifications)
        _options.push({
          value: 'unreleased',
          text: '通知予約',
        });
      return _options;
    });

    const saveMessage = async () => {
      // NOTE: 問題が発生する場合があり、原因の特定ができていないため、一旦コメントアウトで確実に問題ない状態に戻しておく
      if (isUploading.value) {
      // if (uploadingLists.value.length > 0) {
        notification.error('画像をアップロード中ですので、少々お待ちください。');
        return;
      }
      if (!(await confirmDialog('本当に保存しますか？'))) return;
      // nicknameのタイポチェック
      const body01_typoNicknames = message.item.body01
        ?.match(/\{*nickname\}*/g)
        ?.filter((nickname) => nickname !== '{{nickname}}');
      const body02_typoNicknames = message.item.body02
        ?.match(/\{*nickname\}*/g)
        ?.filter((nickname) => nickname !== '{{nickname}}');
      if (
        body01_typoNicknames?.length &&
        !(await confirmDialog(
          `本文1に\n${body01_typoNicknames.join(
            ','
          )}がありますが、\n{{nickname}}の誤入力ではないですか？\n誤入力の場合は「キャンセル」を押して修正してください。`
        ))
      )
        return;
      if (
        body02_typoNicknames?.length &&
        !(await confirmDialog(
          `本文2に\n${body02_typoNicknames.join(
            ','
          )}がありますが、\n{{nickname}}の誤入力ではないですか？\n誤入力の場合は「キャンセル」を押して修正してください。`
        ))
      )
        return;
      isSaving.value = true;
      try {
        if (props.notificationId) {
          await message.saveMessage(props.notificationId);
          notification.notify('変更を保存しました。');
          // 通知予約で更新した場合
          if (message.item.notificationReservationStatus === 'unreleased') {
            // 入力不可にする。
            message.isAllowInput = false;
            // プラン・シーズン名の再取得
            if (message.target.planIds?.length) message.getTargetPlanNames();
            if (message.target.seasonIds?.length) message.getTargetSeasonName();
          }
        } else {
          await message.createMessage();
          notification.notify('新しい通知を作成しました。');
          context.root.$router.push('/messages');
        }
      } catch (error) {
        notification.error(error);
      }
      isSaving.value = false;
    };

    const saveChangeStatus = async () => {
      if (!(await confirmDialog('予約を解除してステータスを下書きに変更します。よろしいですか？'))) return;
      isSaving.value = true;
      try {
        await message.saveMessage(props.notificationId, true);
        await message.getMessage(props.notificationId);
        notification.notify('ステータスを下書きに変更しました。');
      } catch (error) {
        notification.error(error);
      }
      isSaving.value = false;
    };

    // NOTE: 問題が発生する場合があり、原因の特定ができていないため、一旦コメントアウトで確実に問題ない状態に戻しておく
    // const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    //   if (uploadingLists.value.length > 0) {
    //     event.preventDefault();
    //   }
    // };

    // onMounted(() => {
    //   window.addEventListener('beforeunload', handleBeforeUnload);
    // });

    // onUnmounted(() => {
    //   window.removeEventListener('beforeunload', handleBeforeUnload);
    // });

    return {
      pageTitle: 'メッセージBOX',
      myRoleSettings,
      message,
      officialUsers,
      wysiwygSetting,
      notificationTimeOptions,
      reservationStatusOptions,
      senderOptions,
      isSaving,
      saveMessage,
      saveChangeStatus,
      isDialog,
      agree,
      disagree,
      SubscriptionPlansRoleSetting,
      notification,
    };
  },
});
