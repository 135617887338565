import { computed, Ref, ref } from '@vue/composition-api';
import { GetRoleResponse, MyRoleSettings, getRole } from '@/admin/roles';
import { getAttributes } from '@/admin/auth';

class MyAttributes {
  private _isLoading = ref(true);
  private _role: Ref<GetRoleResponse | null> = ref(null); // ログインユーザーのロール情報を保持する
  private _roleId = ref(''); // ログインユーザーのロールIDを保持する
  private _linkedOfficialUserIds: Ref<string[]> = ref([]); // ログインユーザーの紐付け公式ユーザーを保持する
  private _subscriptionPlans: Ref<string[]> = ref([]); // ログインユーザーの紐付けサブスクリプションプランを保持する

  /**
   * ログインユーザーのロール設定を保持する
   *
   * @remarks
   * ログインユーザーのロールが取得できる場合、
   * 取得したロールのロール設定からログインユーザーのロールの権限設定を取得する
   *
   * @returns ログインユーザーのロールの権限設定
   */
  private _myRoleSettings = computed(() => {
    if (!this._role.value) return null;
    const _settings = this._role.value.roleSettings;
    const settings: MyRoleSettings = {
      // TODO: バックエンドに先行してフロント側を上げたいなどあれば、今後修正検討
      users: {
        getOnlyWithoutPersonalInfos: !_settings.endUsers.get && _settings.endUsers.getWithoutPersonalInfos,
        get: _settings.endUsers.get || _settings.endUsers.getWithoutPersonalInfos,
        getUserproperties: _settings.forms.get,
        createAndUpdateNote: _settings.endUsers.createAndUpdateNote,
        joinSubscriptions: _settings.subscriptions.createAndUpdate,
        getGifts: _settings.gifts.get && _settings.histories.get,
        addGifts: _settings.histories.createAndUpdate,
        useGifts: _settings.histories.use,
        getProducts: _settings.products.get && _settings.histories.get,
        useProducts: _settings.histories.use,
        getFavoriteItems: _settings.favoriteItems.get,
      },
      leave: {
        get: _settings.leaveReasons.get,
      },
      testusers: {
        get: _settings.endUsers.get,
        createAndUpdate: _settings.testUsers.createAndUpdate,
      },
      administrators: {
        createAndUpdate: _settings.adminUsers.createAndUpdate,
        delete: _settings.adminUsers.delete,
      },
      forms: {
        get: _settings.forms.get,
        createAndUpdate: _settings.forms.createAndUpdate,
        getEntries: _settings.entries.get,
        getUsers: _settings.endUsers.get || _settings.endUsers.getWithoutPersonalInfos,
        getOnlyWithoutPersonalInfos: !_settings.endUsers.get && _settings.endUsers.getWithoutPersonalInfos,
        getUserproperties: _settings.forms.get,
      },
      favorites: {
        get: _settings.favoriteItems.get,
        createAndUpdate: _settings.favoriteItems.createAndUpdate,
        getPermissions: _settings.permissions.get,
        createAndUpdatePermissions: _settings.permissions.createAndUpdate,
        createAndUpdateSettings: _settings.settings.createAndUpdate,
        deleteSettings: _settings.settings.createAndUpdate,
        getUsers: _settings.endUsers.getWithoutPersonalInfos || _settings.endUsers.get,
        getOnlyWithoutPersonalInfos: !_settings.endUsers.get && _settings.endUsers.getWithoutPersonalInfos,
      },
      officialusers: {
        createAndUpdate: _settings.officialUsers.createAndUpdate,
      },
      talkrooms: {
        get: _settings.talkRooms.get,
        createAndUpdate: _settings.talkRooms.createAndUpdate,
        delete: _settings.talkRooms.delete,
        createAndUpdateBannedWords: _settings.bannedWords.createAndUpdate,
        unrestrictedByLinkedOfficialUsers: _settings.posts.unrestrictedByLinkedOfficialUsers,
        getPosts: _settings.posts.get,
        createAndUpdatePost:
          _settings.posts.createAndUpdate &&
          (_settings.posts.unrestrictedByLinkedOfficialUsers || !!this._linkedOfficialUserIds.value.length),
        deletePost: _settings.posts.delete,
      },
      membershipcards: {
        get: _settings.membershipCards.get,
        createAndUpdate: _settings.membershipCards.createAndUpdate,
        delete: _settings.membershipCards.delete,
      },
      permissions: {
        get: _settings.permissions.get,
        createAndUpdate: _settings.permissions.createAndUpdate,
        delete: _settings.permissions.delete,
      },
      messages: {
        unrestrictedByLinkedOfficialUsers: _settings.notificationReservations.unrestrictedByLinkedOfficialUsers,
        unrestrictedBySubscriptionPlans: _settings.notificationReservations.unrestrictedBySubscriptionPlans,
        get: _settings.notificationReservations.get,
        createAndUpdateDraft: _settings.notificationReservations.createAndUpdate,
        delete: _settings.notificationReservations.delete,
        createNotifications: _settings.notificationReservations.createNotifications,
      },
      birthdayMessages: {
        unrestrictedByLinkedOfficialUsers: _settings.birthdayNotificationReservations.unrestrictedByLinkedOfficialUsers,
        get: _settings.birthdayNotificationReservations.get,
        createAndUpdateDraft: _settings.birthdayNotificationReservations.createAndUpdate,
        delete: _settings.birthdayNotificationReservations.delete,
      },
      reactions: {
        createAndUpdate: _settings.reactions.createAndUpdate,
      },
      histories: {
        get: _settings.histories.get,
        getUsers: _settings.endUsers.get || _settings.endUsers.getWithoutPersonalInfos,
        getOnlyWithoutPersonalInfos: !_settings.endUsers.get && _settings.endUsers.getWithoutPersonalInfos,
        getUserproperties: _settings.forms.get,
      },
      products: {
        unrestrictedByLinkedOfficialUsers: _settings.products.unrestrictedByLinkedOfficialUsers,
        get: _settings.products.get,
        createAndUpdate: _settings.products.createAndUpdate,
        delete: _settings.products.delete,
        getPermissions: _settings.permissions.get,
        createAndUpdatePermissions: _settings.permissions.createAndUpdate,
        getHistories: _settings.histories.get,
        getUsers: _settings.endUsers.get || _settings.endUsers.getWithoutPersonalInfos,
        getOnlyWithoutPersonalInfos: !_settings.endUsers.get && _settings.endUsers.getWithoutPersonalInfos,
        getUserproperties: _settings.forms.get,
      },
      plans: {
        createAndUpdate: _settings.subscriptionPlans.createAndUpdate,
        delete: _settings.subscriptionPlans.delete,
        createAndUpdateProducts: _settings.subscriptionProducts.createAndUpdate,
      },
      subscriptionCampaigns: {
        createAndUpdate: _settings.subscriptionCampaigns.createAndUpdate,
        delete: _settings.subscriptionCampaigns.delete,
      },
      gifts: {
        get: _settings.gifts.get,
        createAndUpdate: _settings.gifts.createAndUpdate,
        delete: _settings.gifts.delete,
        addGifts: _settings.histories.createAndUpdate,
        getConditions: _settings.giftConditions.get,
        createAndUpdateConditions: _settings.giftConditions.createAndUpdate,
        deleteConditions: _settings.giftConditions.delete,
        getHistories: _settings.histories.get,
        getUsers: _settings.endUsers.get || _settings.endUsers.getWithoutPersonalInfos,
        getOnlyWithoutPersonalInfos: !_settings.endUsers.get && _settings.endUsers.getWithoutPersonalInfos,
        getUserproperties: _settings.forms.get,
      },
      properties: {
        get: _settings.forms.get,
        createAndUpdate: _settings.forms.createAndUpdate,
      },
      settings: {
        createAndUpdate: _settings.settings.createAndUpdate,
      },
      roles: {
        createAndUpdate: _settings.roles.createAndUpdate,
        delete: _settings.roles.delete,
      },
      mytalks: {
        unrestrictedByLinkedOfficialUsers: _settings.chatRooms.unrestrictedByLinkedOfficialUsers,
        get: _settings.chatRooms.get,
        createAndUpdate: _settings.chatRooms.createAndUpdate,
        delete: _settings.chatRooms.delete,
        getMessages: _settings.chatMessages.get,
        createAndUpdateMessages: _settings.chatMessages.createAndUpdate,
        createAndUpdateReactions: _settings.chatMessages.createAndUpdate,
        deleteMessages: _settings.chatMessages.delete,
        getUsers: _settings.endUsers.get || _settings.endUsers.getWithoutPersonalInfos,
      },
      videos: {
        get: _settings.videos.get,
        createAndUpdate: _settings.videos.createAndUpdate,
        delete: _settings.videos.delete,
      },
      schedules: {
        createAndUpdate: _settings.scheduleEvents.createAndUpdate,
        delete: _settings.scheduleEvents.delete,
        createAndUpdateSettings: _settings.settings.createAndUpdate,
      },
      scheduleCategories: {
        createAndUpdate: _settings.scheduleCategories.createAndUpdate,
        delete: _settings.scheduleCategories.delete,
      },
      counters: {
        createAndUpdate: !!_settings.counters?.createAndUpdate,
      },
      basicAuth: {
        createAndUpdate: !!_settings.basicAuth?.createAndUpdate,
      },
      report: {
        get: _settings.downloadSignedUrlOfStatData.get,
      },
      musics: {
        createAndUpdate: _settings.musics.createAndUpdate,
        delete: _settings.musics.delete,
      },
      lotteries: {
        get: _settings.lotteries.get,
        createAndUpdate: _settings.lotteries.createAndUpdate,
        delete: _settings.lotteries.delete,
      },
      blogs: {
        unrestrictedByLinkedOfficialUsers: _settings.blogs.unrestrictedByLinkedOfficialUsers,
        get: _settings.blogs.get,
        createAndUpdate: _settings.blogs.createAndUpdate,
        delete: _settings.blogs.delete,
      },
      blogArticles: {
        unrestrictedByLinkedOfficialUsers: _settings.blogArticles.unrestrictedByLinkedOfficialUsers,
        unrestrictedBySubscriptionPlans: _settings.blogArticles.unrestrictedBySubscriptionPlans,
        get: _settings.blogArticles.get,
        createAndUpdate: _settings.blogArticles.createAndUpdate,
        delete: _settings.blogArticles.delete,
      },
      permissionGroups: {
        get: _settings.permissionGroups.get,
        createAndUpdate: _settings.permissionGroups.createAndUpdate,
        delete: _settings.permissionGroups.delete,
      },
    };
    return settings;
  });
  private _myRequestPermissions = computed(() => {
    if (!this._role.value) return null;
    const _settings = this._role.value.roleSettings;
    const permissions = {
      users: _settings.endUsers.get,
      usersWithoutPersonalInfos: _settings.endUsers.getWithoutPersonalInfos,
      forms: _settings.forms.get,
      entries: _settings.entries.get,
      notificationReservations: _settings.notificationReservations.get,
      birthdayNotificationReservations: _settings.birthdayNotificationReservations.get,
      histories: _settings.histories.get,
      leaveReasons: _settings.leaveReasons.get,
      membershipCards: _settings.membershipCards.get,
      permissions: _settings.permissions.get,
      talkRooms: _settings.talkRooms.get,
      posts: _settings.posts.get,
      products: _settings.products.get,
      gifts: _settings.gifts.get,
      giftConditions: _settings.giftConditions.get,
      chatRooms: _settings.chatRooms.get,
      chatMessages: _settings.chatMessages.get,
      videos: _settings.videos.get,
      favoriteItems: _settings.favoriteItems.get,
      stat: _settings.downloadSignedUrlOfStatData.get,
    };
    return permissions;
  });
  private _username = ref('');

  get isLoading() {
    return this._isLoading.value;
  }
  get roleId() {
    return this._roleId.value;
  }
  get linkedOfficialUserIds() {
    return this._linkedOfficialUserIds.value;
  }
  get subscriptionPlans() {
    return this._subscriptionPlans.value;
  }
  get myRoleSettings() {
    return this._myRoleSettings.value;
  }
  get myRequestPermissions() {
    return this._myRequestPermissions.value;
  }
  get username() {
    return this._username.value;
  }

  /**
   * ログインユーザーの管理者情報を取得する
   *
   * @remarks
   * 管理ユーザー情報取得APIでログインユーザーの管理者情報を取得し、
   * 以下の情報を保持する
   * ・ロールID
   * ・紐付け公式ユーザー
   * ・紐付けサブスクリプションプラン
   * ・管理ユーザーID
   * ・ロール情報
   */
  getMyRole = async () => {
    this._isLoading.value = true;
    try {
      const attributes = await getAttributes();
      this._roleId.value = attributes.roleId;
      this._linkedOfficialUserIds.value = attributes.linkedOfficialUserIds;
      this._subscriptionPlans.value = attributes.linkedSubscriptionPlanIds;
      this._username.value = attributes.username;
      this._role.value = await getRole(this._roleId.value);
    } catch (e) {
      console.error(e);
    } finally {
      this._isLoading.value = false;
    }
  };

  /**
   * 指定した機能のロール設定を取得する
   *
   * @remarks
   * ログインユーザーのロール設定が取得できる場合、
   * 指定した機能のロール設定を取得する
   *
   * @param contentName - 指定する機能のプロパティ名
   * @returns 指定した機能のロール設定
   */
  getRoleSettings = (contentName: string): { [key: string]: boolean } | undefined => {
    if (!this._myRoleSettings.value) return undefined;
    else return this._myRoleSettings.value[contentName] || undefined;
  };
}

const myAttributes = new MyAttributes();

export default myAttributes;
